.footer__wrapper {
  box-shadow: 0 -8px 32px rgba(0, 0, 0, 0.2);
  background-color: #151c28;
}

.footer {
  @include wrapper(1170px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 44px;
  padding-bottom: 44px;

  @include mq(1100px) {
    flex-flow: row wrap;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  @include mq(600px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.footer__left {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @include mq(1100px) {
    flex: 1 1 100%;
    margin-bottom: 15px;
  }

  @include mq(600px) {
    flex-flow: wrap row;
  }
}

.footer__logo {
  position: relative;
  padding-right: 31px;
  margin-right: 30px;

  &:after {
    @include absolute-vertical-center;
    content: '';
    right: 0;
    height: 34px;
    width: 1px;
    background: rgba(#ffffff, 0.4);
  }

  @include mq(1100px) {
    flex: 1 1 136px;
  }

  @include mq(600px) {
    @include wrapper(166px);
    flex: 1 1 100%;
    margin-bottom: 15px;
    padding-right: 15px;
    &:after {
      display: none;
    }
  }
}

.footer__logo-img {
  display: block;
  width: 100%;
}

.footer__description {
  color: $white;
  font-family: $robotoSlab;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;

  @include mq(1100px) {
    flex: 1 1 calc(100% - 136px);
  }

  @include mq(600px) {
    font-size: 14px;
    flex: 1 1 100%;
    text-align: center;
  }
}

.footer__right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row wrap;

  @include mq(1100px) {
    flex: 1 1 100%;
    justify-content: space-between;
  }

  @include mq(600px) {
    flex-flow: row wrap;
  }
}

.footer__information {
  flex: 1 1 100%;
  justify-content: flex-end;
  display: flex;
  margin-bottom: 22px;

  @include mq(600px) {
    justify-content: space-around;
  }

  @include mq(500px) {
    flex-flow: row wrap;
  }
}

.footer__email-wrapper {
  @include mq(500px) {
    flex: 1 1 100%;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-bottom: 15px;
  }
}

.footer__email {
  color: $white;
  font-family: $robotoSlab;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
  text-decoration: none;
  padding-left: 32px;
  position: relative;

  &:before {
    @include absolute-vertical-center;
    content: '';
    left: 0;
    width: 20px;
    height: 16px;
    background: url($img + 'icons/mail.png');
  }

  @include mq(600px) {
    font-size: 14px;
  }
}

.footer__phone-wrapper {
  @include mq(500px) {
    flex: 1 1 100%;
    text-align: center;
    margin: 0;
    padding: 0;
  }
}

.footer__phone {
  color: $white;
  font-family: $robotoSlab;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
  text-decoration: none;
  padding-left: 32px;
  margin-left: 27px;
  position: relative;

  &:before {
    @include absolute-vertical-center;
    content: '';
    left: 0;
    width: 20px;
    height: 20px;
    background: url($img + 'icons/phone.png');
  }

  @include mq(600px) {
    font-size: 14px;
  }

  @include mq(500px) {
    margin-left: 0;
  }
}

.footer__socials {
  flex: 1 1 100%;
  display: flex;
  justify-content: flex-end;

  @include mq(600px) {
    justify-content: center;
  }
}

.footer__social {
  width: 20px;
  height: 20px;
  position: relative;

  &:after {
    @include absolute-center;
    content: '';
    width: 20px;
    height: 20px;
  }

  &--vk {
    &:after {
      background: url($img + 'icons/vk.png');
    }
  }

  &--fb {
    margin-left: 20px;
    &:after {
      background: url($img + 'icons/facebook.png');
    }
  }

  &--inst {
    margin-left: 20px;
    &:after {
      background: url($img + 'icons/inst.png');
    }
  }
}