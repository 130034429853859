.jcf-select-news__years {

  .jcf-select-text {
    font-family: $robotoSlab;
    font-size: 16px;
    font-weight: 400;
    color: $white;
  }

  .jcf-select-opener {
    display: none;
  }

  .jcf-select-drop {
    position: absolute;
    top: calc(100% + 2px) !important;
    width: 100% !important;
    left: 0;
    z-index: 2;
    background: $mirage;
    border-radius: 4px;
  }

  .jcf-select-drop-content {
    padding: 10px 5px;
  }

  .jcf-list-content ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      text-align: center;
    }
  }

  .jcf-option {
    margin-bottom: 5px;
    text-align: center;
    font-family: $robotoSlab;
    font-size: 16px;
    font-weight: 400;
    color: $white;

    &.jcf-selected {
      color: $outrageous-orange;
    }

    &.jcf-hover {
      color: $outrageous-orange;
    }
  }
}

.jcf-select-performance-buy__header-time {
  display: flex;
  align-items: center;

  .jcf-select-text {
    font-family: $robotoSlab;
    font-size: 16px;
    font-weight: 400;
    color: $white;
    cursor: pointer;
  }

  .jcf-select-opener {
    margin-left: 10px;
    display: inline-block;
    width: 10px;
    height: 5px;
    background: url($img + 'icons/down.png');
    cursor: pointer;
  }

  .jcf-select-drop {
    position: absolute;
    top: 100% !important;
    width: 100% !important;
    left: 0;
    z-index: 2;
    background: #ec4726;
    border-radius: 4px;
  }

  .jcf-select-drop-content {
    padding: 10px 5px;
    width: 100%;
  }

  .jcf-list-content {
    display: block;
    width: 100% !important;
    height: auto !important;
    overflow-y: auto !important;
  }

  .jcf-list-content ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      text-align: center;
    }
  }

  .jcf-scrollable-wrapper {
    width: 100% !important;
    height: auto !important;
  }

  .jcf-option {
    display: block;
    margin-bottom: 25px;
    text-align: center;
    font-family: $robotoSlab;
    font-size: 16px;
    font-weight: 400;
    color: $white;
  }
}