.main {
  background: $pickled-bluewood;
}

.main-news {
  @include wrapper(1170px);
}

.main-news__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 0 40px;

  @include mq(1000px) {
    flex-flow: row wrap;
  }
}

.main-news__header-title {
  color: $white;
  font-family: $robotoSlab;
  font-size: 32px;
  font-weight: bold;

  @include mq(1000px) {
    order: 2;
    flex: 1 1 100%;
    font-size: 24px;
  }
}

.main-news__header-button {
  position: relative;
  color: rgba($white, 0.5);
  font-family: $robotoSlab;
  font-size: 16px;
  text-decoration: none;
  padding-left: 20px;

  &:before {
    @include absolute-vertical-center;
    content: '';
    width: 8px;
    height: 14px;
    left: 0;
    background: url($img + 'icons/back-header.png');
  }

  @include mq(1000px) {
    order: 1;
    flex: 1 1 100%;
    margin-bottom: 20px;
  }
}

.main-news__content-image {
  margin-bottom: 55px;
  width: 100%;

  @include mq(1000px) {
    margin-bottom: 25px;
  }
}

.main-news__content-text {
  color: $white;
  font-family: $robotoSlab;
  font-size: 18px;
  text-decoration: none;
  padding-bottom: 125px;
  padding-left: 100px;
  padding-right: 100px;

  @include mq(1000px) {
    padding: 0 0 60px;
  }
}
