.main-links__content {
  @include wrapper(1170px);
  padding-top: 55px;
  padding-bottom: 115px;
}

.main-links__content-header {
  margin-bottom: 50px;
}

.links__title {
  color: $white;
  font-family: $robotoSlab;
  font-size: 32px;
  font-weight: bold;
}

.main-links__content-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.main-links__content-item {
  flex: 0 1 48.7%;
  margin-bottom: 5px;
  font-family: $robotoSlab;
  font-size: 18px;
  line-height: 1.38;
  background: #344157;
  padding: 25px 10px 18px;
  text-align: center;

  @include mq(700px) {
    font-size: 14px;
    flex: 1 1 100%;
  }
}

.links__content-link {
  color: $white;
  text-decoration: none;
  border-bottom: 1px dashed $outrageous-orange;
}
