.main-content {
  background: $pickled-bluewood;
}

.main-content__header-wrapper {
  background: url($img + '11.jpg') no-repeat 100% 0 $mirage;
  background-size: contain;

  @include mq(1170px) {
    background-size: cover;
  }

  @include mq(820px) {
    background-position: 50% 0;
    background-size: auto;
  }
}

.main-content__header {
  @include wrapper(1170px);
  display: flex;
  justify-content: space-between;
  position: relative;
}

.main-content__header-left {
  flex: 0 1 34.615%;
  padding-top: 160px;
  padding-bottom: 150px;

  @include mq(1150px) {
    flex: 0 1 calc(100% - 100px);
  }

  @include mq(520px) {
    flex: 0 1 calc(100% - 55px);
    padding: 80px 0;
  }
}

.main-content__header-left--title {
  color: $white;
  font-family: $robotoSlab;
  font-size: 60px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 32px;

  @include mq(520px) {
    font-size: 32px;
    margin-bottom: 18px;
  }
}

.main-content__header-left--text {
  color: rgba($white, 0.6);
  font-family: $robotoSlab;
  font-size: 18px;
  line-height: 1.4;
  margin-bottom: 34px;

  @include mq(520px) {
    font-size: 16px;
    margin-bottom: 16px;
  }
}

.main-content__header-time {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 0 30px 0 0;
  margin: 0 0 30px;
  list-style: none;

  @include mq(520px) {
    padding: 0;
  }
}

.header-time__performances-item {
  flex: 0 1 30%;
  padding: 12px;
  list-style: none;
  color: $white;
  font-family: $roboto;
  font-size: 14px;
  line-height: 1.28;
  border: 1px solid $pickled-bluewood;   /*   set the color border   */
  margin-bottom: 15px;

  @include mq(520px) {
    flex: 0 1 47.5%;
    font-size: 12px;
  }
}

.main-content__right {
  flex: 0 1 65.385%;
  padding-top: 185px;

  @include mq(1150px) {
    flex: 0 1 100px;
  }

  @include mq(520px) {
    flex: 0 1 40px;
  }
}

.main-content__right-info {
  display: flex;
  flex-flow: wrap row;
  padding: 0;
  margin: 0;
  list-style: none;
}

.right-info__performances-item {
  flex: 1 1 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0
  }
}

.right-info__performances-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  list-style: none;
  color: $white;
  font-family: $robotoSlab;
  font-size: 14px;
  text-align: center;
  border-radius: 27px;
  background: $outrageous-orange;

  @include mq(520px) {
    width: 40px;
    height: 40px;
  }
}

.main-content__materials {
  @include wrapper(1170px);
}

.main-content__materials-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 0 40px;

  @include mq(500px) {
    flex-flow: row wrap;
    margin: 30px 0 20px;
  }
}

.main-content__materials-title {
  @include mq(500px) {
    flex: 1 1 100%;
    text-align: center;
    margin-bottom: 20px;
  }
}

.materials-title__title {
  color: $white;
  font-family: $robotoSlab;
  font-size: 32px;
  font-weight: bold;
}

.main-content__materials-month {
  position: relative;
  @include mq(500px) {
    flex: 1 1 100%;
  }
}

.materials-month-select__header {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 195px;
  height: 50px;
  border: 1px solid rgba($white, 0.1);
  border-radius: 4px;
  cursor: pointer;

  &.active {
    background: $outrageous-orange;
  }
}

.materials-month-select__header-text {
  position: relative;
  padding-left: 26px;
  font-family: $robotoSlab;
  font-size: 16px;
  font-weight: 400;
  color: $white;

  &:before {
    @include absolute-vertical-center;
    content: '';
    left: 0;
    width: 16px;
    height: 18px;
    background: url($img + 'icons/calendar.png');
  }
}

.materials-month-select__body {
  position: absolute;
  display: none;
  background: #344157;
  border-radius: 4px;
  top: 100%;
  left: 0;
  width: 280px;
  padding: 30px;

  &.show {
    display: inherit;
  }

  @include mq(1400px) {
    left: auto;
    right: 0;
  }

  @include mq(500px) {
    width: 100%;
  }
}

.materials-month-select__year {
  margin-bottom: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.materials-month-select__nav {
  width: 10px;
  height: 16px;
  cursor: pointer;
}

.materials-month-select__prev {
  background: url($img + 'icons/left.png');
}

.materials-month-select__next {
  background: url($img + 'icons/right.png');
}

.materials-month-select__years {
  font-family: $robotoSlab;
  font-weight: 700;
  font-size: 18px;
  color: $white;
}

.materials-month-select__months {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 0 12px;
}

.materials-month-select__month {
  flex: 0 1 40%;
  margin-bottom: 15px;
  font-family: $robotoSlab;
  font-weight: 400;
  font-size: 16px;
  color: $white;
  cursor: pointer;

  &.active {
    color: $outrageous-orange;
  }
}

.materials-month-select__submit {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $robotoSlab;
  font-weight: 700;
  font-size: 20px;
  padding: 10px 0;
  background: $outrageous-orange;
  border-radius: 4px;
  color: $white;
}

.main-content__material-list {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 50px;
}

.main-content-list__item {
  padding: 55px 60px 55px 110px;
  flex: 1 1 100%;
  background: #344157;
  display: flex;
  margin-bottom: 1px;
  justify-content: space-between;
  align-items: center;

  @include mq(1100px) {
    flex-flow: row wrap;
  }

  @include mq(600px) {
    padding: 25px;
  }
}

.content-list__item-info {
  display: flex;
  align-items: center;

  @include mq(1100px) {
    flex: 1 1 100%;
    margin-bottom: 20px;
  }

  @include mq(850px) {
    flex-flow: row wrap;
  }
}

.content-list__item-number {
  color: $white;
  font-family: $robotoSlab;
  font-size: 90px;
  margin-right: 20px;

  @include mq(850px) {
    flex: 0 1 auto;
    margin-right: 15px;
  }

  @include mq(600px) {
    font-size: 60px;
  }
}

.content-list__item-data {
  position: relative;
  color: $white;
  font-family: $robotoSlab;
  font-size: 18px;
  padding-right: 64px;
  margin-right: 40px;

  &:after {
    @include absolute-vertical-center;
    content: '';
    right: 0;
    height: 100%;
    width: 1px;
    background: $outrageous-orange;
  }

  @include mq(850px) {
    flex: 0 1 auto;
    margin: 0;
    padding: 0;

    &:after {
      display: none;
    }
  }

  @include mq(600px) {
    font-size: 16px;
  }

  @include mq(500px) {
    font-size: 14px;
  }
}

.content-list__item-name {
  @include mq(850px) {
    flex: 1 1 100%;
    text-align: center;
  }
}

.content-list__item-title {
  color: $white;
  font-family: $robotoSlab;
  font-size: 35px;

  @include mq(600px) {
    font-size: 24px;
  }
}

.content-list__item-constraint {
  color: rgba($white, 0.3);
  font-family: $roboto;
  font-size: 12px;
  padding-left: 12px;
  vertical-align: super;
  display: inline;
}

.content-list__item-author {
  color: rgba($white, 0.3);
  font-family: $robotoSlab;
  font-size: 16px;

  @include mq(600px) {
    font-size: 14px;
  }
}

.main-content__header-link {
  position: absolute;
  right:  - 65px;
  bottom: 0;
  transform: translate(100%, 50%);

  @include mq(1774px) {
    right:  15px;
    transform: translate(0, 50%);
  }
}

.content-list__items-buy {
  @include mq(1100px) {
    @include wrapper(280px);
    flex: 1 1 100%;
    padding: 0;
  }
}

.content-list__items-buy-button {
  @include mq(1100px) {
    width: 100%;
  }
}

.main-content-list__link-more-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 55px;
}

.main-content-list__link-more {
  position: relative;
  display: inline-block;
  padding-left: 40px;
  text-align: center;
  font-family: $robotoSlab;
  font-weight: 400;
  font-size: 18px;
  color: rgba($white, 0.6);
  cursor: pointer;

  &:before {
    @include absolute-vertical-center;
    content: '';
    left: 0;
    width: 26px;
    height: 26px;
    background: url($img + "icons/load-more.png");
  }
}
