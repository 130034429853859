.main-poster__content {
  @include wrapper(1170px);
  padding-top: 55px;
  padding-bottom: 115px;
}

.main-poster__content-header {
  margin-bottom: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mq(920px) {
    flex-flow: row wrap;
  }
}

.main-poster__content-title {
  @include mq(920px) {
    flex: 1 1 100%;
    margin-bottom: 20px;
  }
}

.poster-title__title {
  color: $white;
  font-family: $robotoSlab;
  font-size: 32px;
  font-weight: bold;
  line-height: 0.78;
}