.main-gallery__header {
  @include wrapper(1170px);
  padding-top: 55px;
  padding-bottom: 45px;
}

.main-gallery__header-title {
  color: $white;
  font-family: $robotoSlab;
  font-size: 32px;
  font-weight: bold;
}

.main-gallery__content {
  @include wrapper(1170px);
  padding-bottom: 90px;
}

.main-gallery__content-header {
  position: relative;
  margin-bottom: 30px;
}

.main-gallery__content-list {
  display: flex;
  flex-flow: wrap row;
  justify-content: space-between;
}

.main-gallery__content-item {
  position: relative;
  margin-bottom: 30px;

  &--big {
    flex: 0 1 57.5%;

    @include mq(1170px) {
      flex: 1 1 100%;
    }
  }

  &--little {
    flex: 0 1 40%;

    @include mq(1170px) {
      flex: 1 1 100%;
    }
  }
}

.main-gallery__content-size {
  position: absolute;
  top: 40px;
  width: 335px;
  left: -10px;
  padding: 10px 0 12px 60px;
  color: $white;
  font-family: $robotoSlab;
  font-size: 18px;
  font-weight: bold;
  background: $outrageous-orange;

  @include mq(1170px) {
    width: 285px;
  }

  @include mq(720px) {
    top: 30px;
    padding: 5px 0 7px 20px;
    font-size: 16px;
  }

  @include mq(500px) {
    top: 15px;
    padding: 5px 0 7px 15px;
    font-size: 14px;
  }

  .main-gallery__content-header & {
    top: 60px;

    @include mq(720px) {
      top: 30px;
    }

    @include mq(500px) {
      top: 15px;
    }
  }

  &:after {
    position: absolute;
    content: '';
    left: 0;
    top: 100%;
    border: 5px solid transparent;
    border-top: 5px solid #da3f20;
    border-right: 5px solid #da3f20;
  }
}

.main-gallery__content-image {
  width: 100%;
}

.main-gallery__content-title {
  position: absolute;
  bottom: 55px;
  padding-left: 70px;
  width: calc(100% - 70px);
  color: $white;
  font-family: $robotoSlab;
  font-size: 22px;
  font-weight: bold;

  @include mq(720px) {
    bottom: 50px;
    padding-left: 45px;
    font-size: 18px;
    width: calc(100% - 45px);
  }

  @include mq(500px) {
    bottom: 35px;
    padding-left: 25px;
    font-size: 16px;
    width: calc(100% - 25px);
  }

  &--header {
    color: $white;
    font-family: $robotoSlab;
    font-size: 30px;
    font-weight: bold;

    @include mq(720px) {
      font-size: 18px;
      bottom: 35px;
    }

    @include mq(500px) {
      font-size: 16px;
      bottom: 15px;
    }
  }
}


