.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  background-color: #ff5330;
  padding: 19px 41px;
  text-decoration: none;
  color: $white;
  font-family: $robotoSlab;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;

  &:hover {
    background-color: #ec4726;
  }

  @include mq(1000px) {
    padding: 19px 31px;
  }

  @include mq(600px) {
    font-size: 14px;
    padding: 15px 25px;
  }
}