.main-cabinet-entrance {
  background: $pickled-bluewood;
}

.main-content__entrance-cabinet {
  @include wrapper(1170px);
  padding-top: 55px;
  padding-bottom: 150px;
}

.main-content__entrance-cabinet-header {
  margin-bottom: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mq(1000px) {
    flex-flow: row wrap;
  }
}

.main-content__cabinet-title {
  color: $white;
  font-family: $robotoSlab;
  font-size: 32px;
  font-weight: bold;

  @include mq(1000px) {
    flex: 1 1 100%;
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.main-content__cabinet-link-items {
  position: relative;
  color: rgba($white, 0.5);
  font-family: $robotoSlab;
  font-size: 16px;
  text-decoration: none;
  padding-left: 20px;
  width: 100%;
  display: block;

  &:before {
    @include absolute-vertical-center;
    content: '';
    width: 8px;
    height: 14px;
    left: 0;
    background: url($img + 'icons/back-header.png');
  }

  @include mq(1000px) {
    flex: 1 1 100%;
  }
}

.main-content__entrance-cabinet-content {
  display: flex;
  align-items: flex-start;

  @include mq(900px) {
    flex-flow: row wrap;
  }
}

.main-content__entrance-cabinet-form {
  flex: 0 1 50%;
  background: #344157;
  padding: 60px 70px 78px;

  @include mq(900px) {
    flex: 1 1 100%;
    padding: 30px 20px;
    order: 2;
  }
}

.cabinet-form__label {
  display: block;
  font-size: 16px;
  font-weight: 400;
  font-family: $robotoSlab;
  color: $white;
  margin-bottom: 12px;
}

.cabinet-form__input {
  border: none;
  border-radius: 4px;
  color: rgba($white, 0.7);
  font-family: $roboto;
  font-size: 16px;
  background: #5c6574;
  padding-left: 30px;
  height: 45px;
  width: 100%;
  margin-bottom: 20px;

  @include mq(700px) {
    padding: 5px 15px;
  }

  @include mq(400px) {
    flex: 0 1 calc(100% - 50px);
    font-size: 12px;
  }

  &:focus, &:active {
    outline: 0;
  }

  &::-webkit-input-placeholder {
    color: rgba($white, 0.7);
  }

  &::-moz-placeholder {
    color: rgba($white, 0.7);
  }

  &:-ms-input-placeholder {
    color: rgba($white, 0.7);
  }

  &:-moz-placeholder {
    color: rgba($white, 0.7);
  }
}

.main-content__entrance-cabinet-form-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;

  @include mq(440px) {
    flex-flow: row wrap;
  }
}

.cabinet-form__input-checkbox-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mq(440px) {
    margin-bottom: 20px;
    flex: 1 1 100%;
    justify-content: flex-start;
  }
}

.cabinet-form__input-checkbox {
  &:active, &:focus {
    outline: 0;
  }
}

.cabinet-form__label-checkbox {
  color: $white;
  font-family: $roboto;
  font-size: 16px;
  padding-left: 15px;
}

.main-content__entrance-cabinet-form-link {
  color: $white;
  font-family: $roboto;
  font-size: 16px;
  text-decoration: none;

  @include mq(440px) {
    flex: 1 1 100%;
    text-align: center;
  }

  &:focus, &:active {
    outline: 0;
  }
}

.go-entrance-cabinet__submit {
  border: none;
  width: 100%;
}

.main-content__entrance-cabinet-check {
  position: relative;
  flex: 0 1 50%;

  @include mq(900px) {
    @include wrapper(570px);
    flex: 1 1 100%;
    padding: 0;
    order: 1;
  }
}

.main-content__entrance-cabinet-check-img {
  width: 100%;
}

.main-content__entrance-cabinet-check-text {
  position: absolute;
  color: $white;
  font-family: $robotoSlab;
  font-size: 23px;
  font-weight: bold;
  text-align: center;
  width: 100%;
  top: 40px;

  @include mq(460px) {
    top: 25px;
    font-size: 16px;
  }
}

.go-check__link {
  @include absolute-horizontal-center;
  display: block;
  text-align: center;
  color: $white;
  font-family: $robotoSlab;
  font-size: 16px;
  text-decoration: none;
  flex: 0 1 100%;
  padding: 15px 0;
  border: 1px solid $white;
  border-radius: 6px;
  width: 78%;
  bottom: 75px;

  @include mq(460px) {
    bottom: 25px;
    font-size: 13px;
  }
}








