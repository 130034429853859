.main-guestbook {
  background: $pickled-bluewood;
}

.main-guestbook__header {
  @include wrapper(1170px);
  padding-top: 55px;
}

.main-guestbook__header-title {
  color: $white;
  font-family: $robotoSlab;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 45px;
}

.main-guestbook__header-content {
  padding-bottom: 100px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.main-guestbook__header-left {
  flex: 0 1 50%;
  position: relative;

  @include mq(1170px) {
    flex: 1 1 100%;
    margin-bottom: 20px;
    @include wrapper(600px);
    padding: 0;
  }
}

.main-guestbook__header-left-image {
  width: 100%;
}

.main-guestbook__header-left-text {
  @include absolute-horizontal-center;
  top: 65px;
  width: 100%;
  text-align: center;
  font-family: $robotoSlab;
  font-size: 26px;
  color: $white;
  font-weight: bold;
}

.main-guestbook__header-right {
  flex: 0 1 50%;
  background: #344157;
  padding: 60px 70px 65px;

  @include mq(1170px) {
    flex: 1 1 100%;
    margin-bottom: 20px;
  }

  @include mq(700px) {
    padding: 30px 35px;
  }

  @include mq(420px) {
    padding: 15px;
  }
}

.main-guestbook__header-forms {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.questbook-form__input-wrapper {
  flex: 1 1 100%;

  &--half {
    flex: 0 1 48.8%;

    @include mq(1170px) {
      flex: 1 1 100%;
    }
  }
}

.questbook-form__label {
  color: $white;
  font-family: $robotoSlab;
  font-size: 16px;
  display: block;
  margin-bottom: 10px;
}

.questbook-form__input {
  color: $white;
  font-family: $roboto;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  background: #5c6574;
  width: 100%;
  height: 50px;
  margin-bottom: 22px;
  padding-left: 30px;

  &:focus, &:active {
    outline: 0;
  }

  &::-webkit-input-placeholder {
    color: $white;
  }

  &::-moz-placeholder {
    color: $white;
  }

  &:-ms-input-placeholder {
    color: $white;
  }

  &:-moz-placeholder {
    color: $white;
  }
}

.questbook-form__textarea {
  color: $white;
  font-family: $roboto;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  background: #5c6574;
  width: 100% !important;
  min-height: 150px;
  padding-left: 30px;
  padding-top: 15px;
  resize: none;
  margin-bottom: 22px;

  &:focus, &:active {
    outline: 0;
  }

  &::-webkit-input-placeholder {
    color: $white;
  }

  &::-moz-placeholder {
    color: $white;
  }

  &:-ms-input-placeholder {
    color: $white;
  }

  &:-moz-placeholder {
    color: $white;
  }
}

.go-massage-questbook__submit {
  border: none;
  width: 100%;
}

.main-guestbook__content {
  @include wrapper(1170px);
  padding-bottom: 100px;
}

.main-guestbook__content-title {
  color: $white;
  font-family: $robotoSlab;
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 45px;
}

.main-guestbook__content-wrapper {
  background: #344157;
  position: relative;
  padding: 45px 55px 60px;

  @include mq(700px) {
    padding: 25px 25px 50px;
  }

  @include mq(500px) {
    padding: 15px 15px 50px;
  }
}

.main-guestbook__content-comments {
  margin-bottom: 25px;
}

.main-guestbook__your-comment {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.main-guestbook__comment-image {
  @extend .guestbook__comment-image;
  flex: 0 1 68px;
}

.main-guestbook__your-comment-input {
  flex: 0 1 calc(100% - 100px);
  color: $white;
  font-family: $roboto;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  background: #5c6574;
  padding: 5px 60px 5px 30px;
  resize: none;

  @include mq(700px) {
    padding: 5px 40px 5px 15px;
  }

  @include mq(400px) {
    flex: 0 1 calc(100% - 50px);
  }

  &:focus, &:active {
    outline: 0;
  }

  &::-webkit-input-placeholder {
    color: $white;
  }

  &::-moz-placeholder {
    color: $white;
  }

  &:-ms-input-placeholder {
    color: $white;
  }

  &:-moz-placeholder {
    color: $white;
  }
}

.send-massage-questbook__submit {
  @include absolute-vertical-center;
  content: '';
  width: 24px;
  height: 22px;
  right: 35px;
  background: url($img + 'icons/camera.png');

  @include mq(700px) {
    right: 10px;
  }
}

.guestbook__tab-container {
  margin-bottom: 50px;
}

.guestbook__tabs {
  margin-bottom: 35px;
}

.guestbook__tabs-head {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0 0 22px;
  display: flex;

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    background: #5c6574;
  }
}

.guestbook__tab {
  margin: 0 25px;
  position: relative;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &.active {
    .guestbook__tab-link {
      color: $outrageous-orange;
    }

    &:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 4px;
      bottom: -23px;
      z-index: 1;
      left: 0;
      background: $outrageous-orange;
    }
  }

  @include mq(600px) {
    margin: 0 10px;
  }
}

.guestbook__tab-link {
  color: $white;
  font-family: $roboto;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;

  &.active {
    color: $outrageous-orange;
  }
}

.guestbook__comment {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.guestbook__comment-image {
  flex: 0 1 68px;
  width: 68px;
  height: 75px;
  border-radius: 4px;
  background: $outrageous-orange;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  @include mq(400px) {
    width: 35px;
    height: 38px;
    flex: 0 1 35px;
  }
}

.guestbook__comment-content {
  flex: 0 1 calc(100% - 85px);

  @include mq(400px) {
    flex: 0 1 calc(100% - 50px);
  }
}

.guestbook__comment-header {
  color: $white;
  font-family: $robotoSlab;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 12px;

  span {
    font-family: $roboto;
    font-size: 12px;
    color: rgba($white, 0.6);
  }

  @include mq(500px) {
    span {
      display: block;
      margin-top: 8px;
    }
  }
}

.guestbook__comment-text {
  color: $white;
  font-family: $roboto;
  font-size: 16px;
  margin-bottom: 16px;
}

.guestbook__comment-button {
  color: rgba($white, 0.6);
  font-family: $roboto;
  font-size: 16px;
  font-weight: bold;
  border: none;
  background: transparent;
}

.guestbook__comment-more {
  width: 100%;
  display: block;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
}


