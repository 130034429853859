.main-gallery-video {
  background: $pickled-bluewood;
}

.main-gallery-video__header {
  @include wrapper(1170px);
  padding-top: 55px;
  margin-bottom: 40px;
}

.main-gallery-video__header-title {
  color: $white;
  font-family: $robotoSlab;
  font-size: 32px;
  font-weight: bold;
}

.main-gallery-video__content {
  @include wrapper(1170px);
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding-bottom: 90px;
}

.main-gallery-video__content-item {
  margin-bottom: 30px;
  flex: 0 1 31.57%;

  @include mq(928px) {
    flex: 0 1 48%;
  }

  @include mq(628px) {
    flex: 1 1 100%;
  }
}

.main-gallery-video__content-link {
  text-decoration: none;

}

.main-gallery-video__content-item--image-wrapper {
  position: relative;
  display: block;

  &:before {
    @include absolute-center;
    content: '';
    width: 69px;
    height: 52px;
    background: url($img + 'icons/play.png');
  }
}

.main-gallery-video__content-item--image {
  width: 100%;
}

.main-gallery-video__content-item-text {
  background: $outrageous-orange;
  padding: 20px 10px 22px;
  text-align: center;
  text-decoration: none;
  color: $white;
  font-family: $robotoSlab;
  font-size: 18px;
  font-weight: bold;

  @include mq(360px) {
    font-size: 16px;
  }
}
