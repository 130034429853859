.main-news-home {
  background: $pickled-bluewood;
}

.main-news-home__header {
  @include wrapper(1170px);
  padding-top: 58px;
  margin-bottom: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-news-home__header-title {
  color: $white;
  font-family: $robotoSlab;
  font-size: 32px;
  font-weight: bold;
}

.main-news-home__header-years {
  padding: 15px 25px;
  background: transparent;
  border: 1px solid rgba($white, 0.2);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;

  img {
    margin-right: 8px;
  }
}

.news__years {
  background: transparent;
  border: none;
  color: $white;
  font-family: $robotoSlab;
  font-size: 16px;
}

.main-news-home__content {
  @include wrapper(1170px);
  padding-bottom: 90px;
}

.main-news-home__list {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.main-news-home__item {
  position: relative;
  flex: 0 1 31.58%;
  margin-bottom: 30px;

  @include mq(1000px) {
    flex: 0 1 48%;
  }

  @include mq(650px) {
    flex: 1 1 100%;
  }

  &--big {
    flex: 1 1 100%;

    .main-news-home__item-image {
      width: 100%;
    }
  }
}

.main-news-home__item-image {
  width: 100%;
}

.main-news-home__item-title {
  position: absolute;
  bottom: 60px;
  width: 100%;
  left: 0;
  padding: 0 50px;
  color: $white;
  font-family: $robotoSlab;
  font-size: 22px;
  font-weight: bold;

  @include mq(820px) {
    font-size: 20px;
    padding: 20px;
    bottom: 30px;
  }

  @include mq(380px) {
    padding: 15px;
    font-size: 16px;
  }

  .main-news-home__item--big & {
    font-size: 30px;

    @include mq(650px) {
      font-size: 20px;
    }

    @include mq(380px) {
      bottom: 15px;
      font-size: 16px;
    }
  }
}

.main-news-home__item-description {
  position: absolute;
  top: 40px;
  width: 93%;
  left: -10px;
  padding: 12px 0 8px 50px;
  color: $white;
  font-family: $robotoSlab;
  font-size: 18px;
  font-weight: bold;
  background: $outrageous-orange;

  .main-news-home__item--big & {
    top: 50px;
    width: 335px;

    @include mq(650px) {
      display: none;
    }
  }

  &:after {
    position: absolute;
    content: '';
    left: 0;
    top: 100%;
    border: 5px solid transparent;
    border-top: 5px solid #da3f20;
    border-right: 5px solid #da3f20;
  }
}









