.main-profile {
  background: $pickled-bluewood;
}

.main-profile__content {
  @include wrapper(1170px);
  padding-top: 55px;
  padding-bottom: 300px;
}

.main-profile__content-header {

  margin-bottom: 35px;
}

.main-profile__content-title {
  color: $white;
  font-family: $robotoSlab;
  font-size: 32px;
}

.main-content__profile-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include mq(700px) {
    flex-flow: row wrap;
  }
}

.main-content__profile-photo {
  flex: 0 1 31.7%;

  @include mq(700px) {
    @include wrapper(300px);
    flex: 1 1 100%;
    padding: 0;
    margin-bottom: 20px;
  }
}

.main-content__profile-image {
  width: 100%;
}

.main-content__profile-list {
  flex: 0 1 65.7%;
  background: #344157;
  padding: 45px 60px 80px;

  table {
    width: 100%;
  }

  td {
    border: 1px solid rgba($white, 0.1);
    padding: 25px 0;

  }

  @include mq(700px) {
    flex: 1 1 100%;
    padding: 25px 30px 40px;
  }

  @include mq(500px) {
    flex: 1 1 100%;
    padding: 15px;
  }
}

.main-content__profile-item:first-child {
  td {
    padding-top: 0;
  }
}

td.main-content__profile-name {
  color: $white;
  font-family: $robotoSlab;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  border-width: 0 1px 1px 0;

  @include mq(700px) {
    font-size: 14px;
  }

  label {
    @include mq(700px) {
      font-size: 14px;
    }

    @include mq(400px) {
      font-size: 10px;
    }
  }
}

td.main-content__profile-value {
  color: $white;
  font-family: $robotoSlab;
  font-size: 16px;
  border-width: 0 0 1px 1px;
  padding-left: 33px;

  @include mq(700px) {
    font-size: 12px;
  }
}

.main-content__profile-edit-button {
  margin-top: 40px;
}

.edit-profile__link {
  color: $white;
  font-family: $robotoSlab;
  font-size: 16px;
  width: 100%;
  border: none;
}
