.main-performances {
  background: $pickled-bluewood;
}

.main-performances__header {
  @include wrapper(1170px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 52px;
  margin-bottom: 30px;

  @include mq(730px) {
    flex-flow: row wrap;
  }
}

.main-performances__header-title {
  color: $white;
  font-family: $robotoSlab;
  font-size: 32px;
  font-weight: bold;

  @include mq(730px) {
    flex: 1 1 100%;
    margin-bottom: 20px;
  }
}

.main-performances__header-buttons {
  @include mq(730px) {
    flex: 1 1 100%;
    display: flex;
    justify-content: space-between;
  }
}

.main-performances__header-button {
  width: 130px;
  height: 50px;
  box-sizing: border-box;
  color: $white;
  font-family: $robotoSlab;
  font-size: 16px;
  background: transparent;
  border: 1px solid rgba($white, 0.3);
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  @include mq(730px) {
    width: 48%;
  }

  &:hover {
    background: $outrageous-orange;
  }

  &.active {
    background: $outrageous-orange;
  }

  span {
    display: inline-block;
    margin-right: 7px;
  }

  &--soon {
    margin-right: 20px;

    span {
      width: 16px;
      height: 18px;
      background: url($img + 'icons/calendar.png');
    }
  }

  &--archive {
    span {
      width: 16px;
      height: 16px;
      background: url($img + 'icons/archive.png');
    }
  }
}

.main-performances__content {
  @include wrapper(1170px);
}

.main-performances__content-list {
  padding-bottom: 70px;
}

.main-performances__content-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  @include mq(1000px) {
    flex-flow: row wrap;
  }
}

.main-performances__content-items-wrapper {
  flex: 0 1 31.6%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  @include mq(1000px) {
    flex: 1 1 100%;
  }
}

.main-performances__content-item {
  position: relative;

  &--big {
    flex: 0 1 65.7%;

    @include mq(1000px) {
      flex: 1 1 100%;
      margin-bottom: 30px;
    }
  }

  &--small {
    flex: 1 1 100%;

    &:first-child {
      margin-bottom: 30px;
    }

    @include mq(1000px) {
      flex: 0 1 48%;

      &:first-child {
        margin-bottom: 0;
      }
    }

    @include mq(650px) {
      flex: 1 1 100%;

      &:first-child {
        margin-bottom: 30px;
      }
    }
  }
}

.main-performances__content-link {
  text-decoration: none;
}

.main-performances__content-img {
  width: 100%;
}

.main-performances__content-item-text {
  position: absolute;
  bottom: 40px;
  padding: 12px 65px 12px 40px;
  z-index: 1;
  left: -10px;
  background: $outrageous-orange;
  color: $white;
  font-family: $robotoSlab;
  font-size: 18px;
  font-weight: bold;

  .main-performances__content-item--big & {
    font-size: 16px;
    bottom: 50px;

    span {
      font-size: 32px;
      font-weight: bold;
    }

    @include mq(650px) {
      font-size: 14px;
      bottom: 20px;

      span {
        font-size: 20px;
      }
    }
  }

  @include mq(650px) {
    bottom: 20px;
    padding: 12px 30px 12px 20px;
    font-size: 16px;
  }

  &:after {
    position: absolute;
    content: '';
    left: 0;
    top: 100%;
    border: 5px solid transparent;
    border-top: 5px solid #da3f20;
    border-right: 5px solid #da3f20;
  }
}

.main-performances__content-list-materials {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.content-list__materials-item {
  position: relative;
  flex: 0 1 48.7%;
  margin-bottom: 30px;

  @include mq(950px) {
    flex: 1 1 100%;
  }
}

.content-list__materials-link {
  text-decoration: none;
}

.content-list__materials-img {
  width: 100%;
}

.content-list__materials-item-text {
  padding: 25px 10px 20px 90px;
  width: 450px;
  position: absolute;
  bottom: 55px;
  z-index: 1;
  left: -10px;
  background: $outrageous-orange;
  color: $white;
  font-family: $robotoSlab;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.6;

  span {
    font-size: 26px;
    font-weight: bold;
    line-height: 0.96;
  }

  &:after {
    position: absolute;
    content: '';
    left: 0;
    top: 100%;
    border: 5px solid transparent;
    border-top: 5px solid #da3f20;
    border-right: 5px solid #da3f20;
  }

  @include mq(530px) {
    font-size: 14px;
    line-height: normal;
    width: 100%;
    padding: 15px 30px 10px 10px;
    bottom: 20px;

    span {
      font-size: 18px;
    }
  }
}


