.main-performance-buy {
  background: $pickled-bluewood;
}

.main-performance-buy__header-time-wrapper {
  width: 255px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: $outrageous-orange;
  text-align: center;
  border: none;
  font-family: $robotoSlab;
  font-size: 16px;
  color: $white;
  text-decoration: none;
  position: relative;

  @include mq(400px) {
    width: 100%;
  }
}

.main-performance-buy__booking {
  @include wrapper(1170px);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 100px;

  @include mq(1000px) {
    flex-flow: row wrap;
  }
}

.main-performance-buy__scheme {
  flex: 0 1 65.7%;
  background: #344157;
  padding: 50px 55px 60px;

  @include mq(1000px) {
    flex: 1 1 100%;
    margin-bottom: 20px;
    padding: 15px;
  }
}

.scheme__legend-title {
  margin-bottom: 35px;
}

.scheme__title {
  color: $white;
  font-family: $robotoSlab;
  font-size: 18px;
  font-weight: bold;
  line-height: 0.88;
}

.scheme__main {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}

.scheme__scene {
  flex: 1 1 100%;
  color: $white;
  font-family: $robotoSlab;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 42px;
  padding: 10px 0;
  background: $oslo-gray;
  text-align: center;
  border-radius: 5px;

  @include mq(1000px) {
    margin-bottom: 20px;
  }
}

.scheme__container {
  flex: 1 1 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding-left: 45px;
  margin-bottom: 45px;

  @include mq(1380px) {
    padding-left: 0;
    margin-bottom: 20px;
  }
}

.scheme__places-table {
  flex: 0 1 calc(100% - 40px);
  border-collapse: separate;
  width: calc(100% - 40px);
  border-spacing: 10px;

  @include mq(1000px) {
    border-spacing: 5px;
    width: 100%;
  }
}

.scheme__rows-numbers {
  flex: 0 1 40px;

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 200px;

  @include mq(1380px) {
    width: auto;
  }

  @include mq(800px) {
    display: none;
  }
}

.scheme__rows-item {
  flex: 1 1 100%;
  color: $white;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.36px;
  text-align: end;

  @include mq(1380px) {
    text-align: center;
  }
}

.scheme__places-item {
  color: $white;
  font-family: $robotoSlab;
  font-size: 20px;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  text-align: center;
  background: $oslo-gray;
  empty-cells: hide;

  &:empty {
    pointer-events: none;
  }

  &:hover {
    cursor: pointer;
    background: $outrageous-orange;
  }

  &.sold {
    background: $cardinal;
  }

  &.chosen {
    background: $outrageous-orange;
  }

  @include mq(800px) {
    font-size: 14px;
    width: 25px;
    height: 30px;
  }
}

.scheme__rows-item {
  color: $white;
  font-family: $robotoSlab;
  font-size: 18px;
  line-height: 0.88;
}

.scheme__legend-list {
  display: flex;
  justify-content: space-between;

  @include mq(600px) {
    flex-flow: row wrap;
  }
}

.scheme__legend-item {
  color: $white;
  font-size: 16px;
  font-weight: 300;
  line-height: 1;
  margin-right: 40px;
  padding-left: 35px;
  position: relative;
  font-family: $robotoSlab;


  &:before {
    @include absolute-vertical-center;
    content: '' ;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 2px;
  }

  &--free {
    &:before {
      background-color: $oslo-gray;
    }

  }

  &--chose {
    &:before {
      background-color: $outrageous-orange;
    }
  }

  &--sold {
    margin-right: 0;

    &:before {
      background-color: $cardinal;
    }
  }

  @include mq(600px) {
    font-size: 14px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0
    }
  }
}

.main-performance-buy__order {
  flex: 0 1 31.5%;
  background: #344157;
  padding: 50px 45px;

  @include mq(1000px) {
    flex: 1 1 100%;
    padding: 20px;
  }
}

.main-performance-buy__order-time {
  margin-bottom: 30px;
}

.order-time__text {
  color: $outrageous-orange;
  font-family: $robotoSlab;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
}

.order-time__time {
  color: $white;
  font-family: $robotoSlab;
  font-size: 18px;
  line-height: 0.88;
}

.main-performance-buy__order-performance {
  margin-bottom: 22px;
}

.order-performance__text {
  color: $outrageous-orange;
  font-family: $robotoSlab;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
}

.order-performance__name {
  color: $white;
  font-family: $robotoSlab;
  font-size: 18px;
}

.main-performance-buy__order-places {
  position: relative;
  padding-bottom: 40px;
  margin-bottom: 35px;

  &:before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: $oslo-gray;
  }
}

.order-places__text {
  color: $outrageous-orange;
  font-family: $robotoSlab;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
}

.order-places__list {
  color: $white;
  font-family: $robotoSlab;
  font-size: 18px;
}

.order-places__place {
  margin-bottom: 10px;
}

.main-performance-buy__order-payment {
  margin-bottom: 65px;
}

.order-payment__text {
  color: $outrageous-orange;
  font-family: $robotoSlab;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
}

.order-payment__amount {
  color: $white;
  font-family: $robotoSlab;
  font-size: 18px;
  margin-bottom: 47px;
}

.order-payment__button {
  width: 100%;
}




