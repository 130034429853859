.main-artists {
  background: $pickled-bluewood;
}

.main-artists__header {
  @include wrapper(1170px);
  padding-top: 60px;
}

.main-artists__header-title {
  color: $white;
  font-family: $robotoSlab;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 40px;
}

.main-artists__content {
  @include wrapper(1170px);
  padding-bottom: 85px;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;

  @include mq(950px) {
    justify-content: space-around;
  }
}

.main-artists__content-items {
  flex: 0 1 31.57%;
  margin-bottom: 30px;
  position: relative;

  @include mq(950px) {
    flex: 0 1 41.8%;
  }

  @include mq(550px) {
    flex: 1 1 100%;
  }
}

.main-artists__content-items-image {
  width: 100%;
}

.main-artists__content-items-text {
  position: absolute;
  background: $outrageous-orange;
  color: $white;
  font-family: $robotoSlab;
  font-size: 18px;
  font-weight: bold;
  bottom: 55px;
  width: 295px;
  left: -10px;
  padding: 15px 0 19px 50px;

  &:after {
    position: absolute;
    content: '';
    left: 0;
    top: 100%;
    border: 5px solid transparent;
    border-top: 5px solid #da3f20;
    border-right: 5px solid #da3f20;
  }

  @include mq(950px) {
    width: 100%;
    padding-left: 25px;
    font-size: 16px;
  }

  @include mq(550px) {
    bottom: 20px;
  }
}
