.main-performance__header-wrapper {
  @include wrapper(1170px);
  padding-top: 40px;
  margin-bottom: 50px;
}

.main-performance__header-back {
  margin-bottom: 30px;
}

.main-performance__header-back-link {
  font-family: $robotoSlab;
  font-size: 16px;
  font-weight: 400;
  color: rgba($white, 0.5);
  text-decoration: none;
  padding-left: 20px;
  position: relative;

  &:before {
    @include absolute-vertical-center;
    content: '';
    left: 0;
    height: 14px;
    width: 8px;
    background: url($img + 'icons/back-header.png');
  }
}

.main-performance__header-content {
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  @include mq(844px) {
    flex-flow: row wrap;
  }
}

.main-performance__left {
  position: relative;
  flex: 1 1 50%;

  @include mq(1170px) {
    overflow: hidden;
  }

  @include mq(844px) {
    flex: 1 1 100%;
  }
}

.main-performance__left-image {
  width: 100%;

  @include mq(1170px) {
    width: auto;
    height: 100%;
  }

  @include mq(844px) {
    height: auto;
    width: 100%;
  }
}

.main-performance__left-info {
  @include absolute-vertical-center;
  margin: 0;
  padding: 0;
  list-style: none;
  left: 55px;

  @include mq(544px) {
    left: 25px;
  }
}

.left-info__performance-item {
  width: 55px;
  height: 55px;
  list-style: none;
  color: $white;
  font-family: $robotoSlab;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: $outrageous-orange;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq(544px) {
    width: 35px;
    height: 35px;
    margin-bottom: 15px;
  }
}

.main-performance__right {
  flex: 1 1 50%;
  background: #344157;
  padding: 55px 10px 10px 70px;

  @include mq(844px) {
    flex: 1 1 100%;
  }

  @include mq(544px) {
    padding: 20px;
  }
}

.main-performance__right-title {
  color: $white;
  font-family: $robotoSlab;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 25px;

  span {
    font-family: $roboto;
    font-size: 14px;
    vertical-align: super;
    color: rgba($white, 0.3);
  }
}

.main-performance__right-text {
  color: $outrageous-orange;
  font-family: $robotoSlab;
  font-size: 16px;
  margin-bottom: 25px;
}

.main-performance__right-description {
  font-family: $roboto;
  font-weight: 400;
  font-size: 16px;
  color: $white;
  margin-bottom: 70px;
}

.main-performance__header-time {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 355px;
  margin: 0 0 35px;
  padding: 0;
  list-style: none;

  @include mq(446px) {
    flex-flow: row wrap;
    width: 100%;
    margin-bottom: 25px;
  }
}

.header-time__performance-item {
  flex: 0 1 31.5%;
  padding: 12px;
  color: $white;
  font-family: $roboto;
  font-size: 14px;
  line-height: 1.28;
  list-style: none;
  border: 1px solid rgba($white, 0.3);
  margin-bottom: 13px;
  border-radius: 3px;

  @include mq(446px) {
    flex: 0 1 48%;
  }

  @include mq(360px) {
    flex: 1 1 100%;
  }
}

.main-performance__content {
  @include wrapper(1170px);
  padding-bottom: 100px;
}

.performance__tabs {

}

.performance__tabs-head {
  display: flex;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  list-style: none;

  @include mq(600px) {
    flex-flow: row wrap;
  }
}

.performance__tab {
  background: $mirage;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin-right: 1px;
  text-align: center;

  &:hover {
    .performance__tab-link {
      color: $outrageous-orange;
    }
  }

  @include mq(920px) {
    width: 33.3%;
  }

  @include mq(600px) {
    width: 100%;
    margin: 0 0 1px;
  }
}

.performance__tab-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $white;
  padding: 22px 10px;
  width: 278px;
  font-family: $robotoSlab;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.87;
  text-decoration: none;

  &.active  {
    color: $outrageous-orange;
  }

  @include mq(920px) {
    width: 100%;
    font-size: 14px;
  }
}

.performance__tab-container {
  padding: 42px 50px 50px;
  background: #344157;

  @include mq(920px) {
    padding: 20px 24px 24px;
  }

  @include mq(400px) {
    padding: 15px;
  }

}

.performance__description {
  display: flex;
  justify-content: space-between;

  @include mq(920px) {
    flex-flow: row wrap;
  }
}

.description__body {
  flex: 0 1 calc(100% - 420px);
  padding: 0 15px;
  color: $white;
  font-family: $roboto;
  font-size: 16px;
  line-height: 1.5;

  @include mq(920px) {
    flex: 1 1 100%;
    margin-bottom: 15px;
    padding: 0;
  }
}

.description__slider {
  flex: 0 1 420px;

  @include mq(920px) {
    flex: 1 1 100%;
    width: 100%;
  }
}

.performance__slider {
  width: 420px;
  position: relative;

  @include mq(920px) {
    width: 100%;
  }

  .slick-arrow {
    @include absolute-vertical-center;
    width: 11px;
    height: 20px;
    z-index: 2;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 11px;
      height: 20px;
    }
  }

  .slick-prev {
    left: 0;

    &:before {
      background: url($img + 'icons/back.png');
    }
  }

  .slick-next {
    right: 0;
    background: url($img + 'icons/next.png');
  }
}

.performance__slide-img {
  margin: 0 auto;
  display: block;
}

.creators__list {
  display: flex;
  justify-content: space-between;

  @include mq(900px) {
    flex-flow: row wrap;
  }
}

.creators__items {
  flex: 0 1 48%;
  color: $white;
  font-family: $roboto;
  font-size: 16px;
  line-height: 1.5;

  @include mq(900px) {
    flex: 1 1 100%;
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.actors__list {
  display: flex;
  flex-flow: wrap row;
}

.actors__item {
  flex: 0 1 33.3%;
  color: $white;
  margin-bottom: 12px;
}

.actors__name,
.actors__role {
  color: $white;
  font-family: $roboto;
  font-size: 16px;
  line-height: 1.5;
}

.actors__role {
  color: rgba($white, 0.5);
}
