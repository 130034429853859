.header {
  display: flex;
  flex-flow: row wrap;
  position: relative;
}

.header__head-wrapper {
  flex: 1 1 100%;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.15);
  background-color: #2a374c;
}

.header__head {
  @include wrapper(1170px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;


  @include mq(1000px) {
    height: auto;
    padding-top: 15px;
    padding-bottom: 15px;
    flex-flow: row wrap;
  }
}

.header__left {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @include mq(1000px) {
    flex: 1 1 100%;
    margin-bottom: 15px;
  }

  @include mq(600px) {
    flex-flow: wrap row;
    justify-content: center;
  }
}

.header__logo {
  position: relative;
  padding-right: 23px;
  margin-right: 30px;

  &:after {
    @include absolute-vertical-center;
    content: '';
    width: 1px;
    height: 34px;
    right: 0;
    background: $outrageous-orange;
  }

  @include mq(1000px) {
    flex: 1 1 136px;
  }

  @include mq(600px) {
    @include wrapper(166px);
    flex: 1 1 100%;
    margin-bottom: 15px;
    padding-right: 15px;
    &:after {
      display: none;
    }
  }
}

.header__logo-img {
  display: block;
  width: 100%;
}

.header__description {
  color: $white;
  font-family: $robotoSlab;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;

  @include mq(1000px) {
    flex: 1 1 calc(100% - 136px);
  }

  @include mq(600px) {
    font-size: 14px;
    flex: 1 1 100%;
    text-align: center;
  }
}

.header__right {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include mq(1000px) {
    flex: 1 1 100%;
    justify-content: space-between;
  }

  @include mq(600px) {
    flex-flow: row wrap;
  }
}
.header__order-wrapper {
  @include mq(600px) {
    flex: 1 1 100%;
    text-align: center;
    margin-bottom: 15px;
  }
}

.header__order {
  color: $white;
  font-family: $robotoSlab;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  border-bottom: 1px dashed $outrageous-orange;

  &:hover {
    color: #ec4726;
  }

  @include mq(600px) {
    font-size: 14px;
  }
}

.header__buy-wrapper {
  @include mq(600px) {
    flex: 1 1 100%;
    text-align: center;
    margin-bottom: 15px;
  }
}

.header__buy {
  margin-left: 36px;

  @include mq(600px) {
    margin: 0;
  }
}

.header__nav-wrapper {
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.15);
  background-color: #1e293a;
  flex: 1 1 100%;

  .main & {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 10;
    background: none;
    box-shadow: none;

    @include mq(600px) {
      position: static;
      box-shadow: 0 5px 25px rgba(0, 0, 0, 0.15);
      background-color: #1e293a;
    }
  }
}

.header__nav {

}

.nav {
  @include wrapper(1170px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;

  @include mq(700px) {
    padding: 15px 0;
  }
}

.header__list {
  margin: 0;
  flex: 1 1 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;

  @include mq(700px) {
    position: absolute;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.15);
    background-color: #1e293a;
    left: 0;
    top: 100%;
    width: 100%;
    flex-flow: row wrap;
    justify-content: center;
    padding: 15px 0;
    opacity: 0;
    visibility: hidden;
    z-index: 10;

    &.show {
      opacity: 1;
      visibility: visible;
    }
  }
}

.header__item {
  list-style: none;

  @include mq(700px) {
    flex: 1 1 100%;
    margin-bottom: 10px;
    text-align: center;
  }
}

.header__link {
  opacity: 0.8;
  color: $white;
  font-family: $robotoSlab;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;

  &:hover, &.active {
    opacity: 1;
    color: $outrageous-orange;
  }
}

.burger {
  display: none;

  @include mq(700px) {
    position: relative;
    display: block;
    width: 30px;
    height: 25px;
    transition: .5s ease-in-out;
    cursor: pointer;
  }

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 50%;
    background: $white;
    opacity: 1;
    transform: rotate(0deg);
    transition: .25s ease-in-out;

    &:nth-child(even) {
      left: 50%;
      border-radius: 0 5px 5px 0;
    }

    &:nth-child(odd) {
      left:0;
      border-radius: 5px 0 0 5px;
    }

    &:nth-child(1), &:nth-child(2) {
      top: 0;
    }

    &:nth-child(3), &:nth-child(4) {
      top: 10px;
    }

    &:nth-child(5), &:nth-child(6) {
      top: 20px;
    }
  }

  &.open span:nth-child(1), &.open span:nth-child(6) {
    transform: rotate(45deg);
  }

  &.open span:nth-child(2), &.open span:nth-child(5) {
    transform: rotate(-45deg);
  }

  &.open span:nth-child(1) {
    left: 3px;
    top: 6px;
  }

  &.open span:nth-child(2) {
    left: calc(50% - 3px);
    top: 6px;
  }

  &.open span:nth-child(3) {
    left: 0;
    opacity: 0;
  }

  &.open span:nth-child(4) {
    left: 50%;
    opacity: 0;
  }

  &.open span:nth-child(5) {
    left: 3px;
    top: 15px;
  }

  &.open span:nth-child(6) {
    left: calc(50% - 3px);
    top: 15px;
  }
}