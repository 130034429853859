.main-pen {
  background: $pickled-bluewood;
}

.main-pen__header {
  @include wrapper(1170px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 45px;
  padding-top: 55px;

  @include mq(900px) {
    flex-flow: row wrap;
  }
}

.main-pen__header-title {
  color: $white;
  font-family: $robotoSlab;
  font-size: 32px;
  font-weight: bold;

  @include mq(900px) {
    flex: 1 1 100%;
    margin-bottom: 15px;
  }
}

.main-pen__header-link-back {
  position: relative;
  padding-left: 20px;
  color: rgba($white, 0.5);
  font-family: $robotoSlab;
  font-size: 16px;
  text-decoration: none;

  &:before {
    @include absolute-vertical-center;
    content: '';
    left: 0;
    width: 8px;
    height: 14px;
    background: url($img + 'icons/back-header.png');
  }

  @include mq(900px) {
    flex: 1 1 100%;
  }
}

.main-pen__content {
  @include wrapper(1170px);
  padding-bottom: 70px;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}

.main-pen__content-item {
  flex: 0 1 31.6%;
  height: 250px;
  background: $mirage;
  margin-bottom: 30px;

  @include mq(900px) {
    flex: 0 1 48.7%;
    margin-bottom: 25px;
  }

  @include mq(520px) {
    flex: 1 1 100%;
    margin-bottom: 20px;
  }
}
