.main-restoring__content {
  @include wrapper(630px);
  background: #344157;
  position: relative;

  @include mq(710px) {
    margin: 0 40px;

    @include mq(460px) {
      margin: 0 15px;
    }
  }
}

.main-restoring__content-wrapper {
  padding: 65px 100px 85px;

  @include mq(630px) {
    padding: 35px 50px 30px;
  }

  @include mq(460px) {
    padding: 15px 20px 20px;
  }
}

.main-restoring__content-title {
  color: $white;
  font-family: $robotoSlab;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 45px;

  @include mq(630px) {
    font-size: 18px;
    margin-bottom: 25px;
  }
}

.restoring-form__label {
  display: block;
  font-size: 16px;
  font-weight: 400;
  font-family: $robotoSlab;
  color: $white;
  margin-bottom: 12px;
}

.restoring-form__input {
  border: none;
  border-radius: 4px;
  color: rgba($white, 0.7);
  font-family: $roboto;
  font-size: 16px;
  background: #5c6574;
  padding-left: 30px;
  height: 45px;
  width: 100%;
  margin-bottom: 35px;

  @include mq(700px) {
    padding: 5px 15px;
  }

  @include mq(400px) {
    flex: 0 1 calc(100% - 50px);
    font-size: 12px;
  }

  &:focus, &:active {
    outline: 0;
  }

  &::-webkit-input-placeholder {
    color: rgba($white, 0.7);
  }

  &::-moz-placeholder {
    color: rgba($white, 0.7);
  }

  &:-ms-input-placeholder {
    color: rgba($white, 0.7);
  }

  &:-moz-placeholder {
    color: rgba($white, 0.7);
  }
}

.restoring-profile__submit {
  border: none;
  width: 100%;
}

button.mfp-close {
  position: absolute;
  opacity: 1;
  width: 80px;
  height: 50px;
  right: -40px;
  top: -25px;
  background: $alizarin-crimson;
  border-radius: 4px;

  &:active {
    width: 80px;
    height: 50px;
    right: -40px;
    top: -25px;
  }

  &:after {
    position: absolute;
    content: '';
    right: 0;
    top: 100%;
    border: 20px solid transparent;
    border-left: 20px solid $cardinal;
    border-top: 20px solid $cardinal;
  }

  @include mq(460px) {
    width: 30px;
    height: 40px;
    right: -15px;
    top: -20px;

    &:after {
      display: none;
    }

    &:active {
      width: 30px;
      height: 40px;
      right: -15px;
      top: -20px;
    }
  }
}