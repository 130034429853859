.main-studio {
  background: $pickled-bluewood;
}

.main-studio__header {
  @include wrapper(1170px);
  padding-top: 60px;
}

.main-studio__header-content {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 130px;
}


.main-studio__header-title {
  color: $white;
  font-family: $robotoSlab;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 45px;
}

.main-studio__header-item {
  flex: 0 1 48.7%;
  background: #344157;
  padding: 60px 60px 50px;
}

.studio-left__header-title {
  color: $white;
  font-family: $robotoSlab;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
}

.studio-left__header-info {
  color: $white;
  font-family: $robotoSlab;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 24px;
}

.studio-left__header-phone, .studio-right__header-phone {
  margin-bottom: 16px;
}

.studio-left__header-number, .studio-right__header-number {
  color: $white;
  font-family: $robotoSlab;
  font-size: 16px;
  text-decoration: none;
  padding-left: 33px;
  position: relative;

  &:before {
    @include absolute-vertical-center;
    content: '';
    left: 0;
    width: 20px;
    height: 20px;
    background: url($img + "/icons/phone.png");
  }
}

.studio-header__address-text {
  color: $white;
  font-family: $robotoSlab;
  font-size: 16px;
  padding-left: 33px;
  position: relative;

  &:before {
    @include absolute-vertical-center;
    left: 0;
    content: '';
    width: 16px;
    height: 22px;
    background: url($img + "/icons/maps.png");
  }
}

.studio-right__header-title {
  color: $white;
  font-family: $robotoSlab;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.46;
  margin-bottom: 22px;
}

.studio-right__header-info-title {
  color: $outrageous-orange;
  font-family: $robotoSlab;
  font-size: 18px;
  margin-bottom: 25px;
}

.studio-right__header-info-list {
  margin: 0 0 24px;
  padding: 0;
  list-style-position: inside;
  color: $outrageous-orange;
  font-family: $robotoSlab;
  font-size: 16px;
  line-height: 1.87;

  li span {
    color: $white;
  }
}

.studio-right__header-info-people {
  color: $white;
  font-family: $robotoSlab;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 30px;
}

.main-studio__list-item-title {
  background: $outrageous-orange;
  color: $white;
  font-family: $robotoSlab;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.38;
}

.main-studio__list-item-text {
  color: $white;
  font-family: $robotoSlab;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.36;
}

.main-studio__content {
  @include wrapper(1170px);
  padding-bottom: 90px;
}



