.main-orders {
  background: $pickled-bluewood;
}

.main-content__orders {
  @include wrapper(1170px);
  padding-top: 55px;
  padding-bottom: 90px;
}

.main-content__orders-header {
  margin-bottom: 45px;
}

.main-content__orders-title {
  color: $white;
  font-family: $robotoSlab;
  font-size: 32px;
  font-weight: bold;
}

.main-content__orders-content {
  background: #344157;
  padding: 55px 60px 65px;

  @include mq(920px) {
    padding: 25px 20px 30px;
  }

  @include mq(700px) {
    padding: 15px;
  }

  @include mq(500px) {
    padding: 0;
  }
}

.main-content__orders-table {
  color: $white;
  font-family: $robotoSlab;
  font-size: 16px;
  text-align: center;
  width: 100%;

  td {
    border: solid rgba($white, 0.1);
    border-width: 0 1px 1px 1px;
    padding: 26px 0 20px;
    color: rgba($white, 0.8);

    &:last-child {
      border-width: 0 0 1px 1px;
    }

    &:nth-child(2) {
      max-width: 184px;

      @include mq(500px) {
        border-width: 0 1px 1px 0;
      }
    }

    @include mq(920px) {
      font-size: 14px;
    }

    @include mq(700px) {
      font-size: 12px;
      padding: 16px 5px 10px;
    }

    @include mq(500px) {
      font-size: 8px;
      padding-left: 2px;
      padding-right: 2px;
    }
  }
}

td.orders-table-numbers {
  text-align: left;
  border-width: 0 1px 1px 0;

  @include mq(500px) {
    display: none;
  }
}

.orders-table-title {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 28px;
  border: solid rgba($white, 0.1);
  border-width: 0 1px 1px 1px;

  &:first-child {
    text-align: left;
    border-width: 0 1px 1px 0;

    @include mq(500px) {
      display: none;
    }
  }

  &:last-child {
    border-width: 0 0 1px 1px;
  }

  @include mq(920px) {
    font-size: 16px;
  }

  @include mq(700px) {
    font-size: 12px;
    padding-bottom: 18px;
  }

  &:nth-child(2) {
    max-width: 184px;

    @include mq(500px) {
      border-width: 0 1px 1px 0;
    }
  }

  @include mq(500px) {
    font-size: 8px;
    padding-bottom: 10px;
  }
}

.order-button {
  color: $outrageous-orange;
  border: none;
  background: transparent;
  display: block;
  text-align: center;
  width: 100%;
}


