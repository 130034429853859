.main-profile {
  background: $pickled-bluewood;
}

.main-content__profile-new {
  @include wrapper(1170px);
  padding-top: 60px;
  padding-bottom: 290px;
}

.main-content__profile-new-header {
  margin-bottom: 35px;
}

.main-content__profile-new-title {
  color: $white;
  font-family: $robotoSlab;
  font-size: 32px;
}

.main-content__profile-new-photo-wrapper {
  flex: 0 1 31.7%;

  @include mq(700px) {
    @include wrapper(300px);
    flex: 1 1 100%;
    padding: 0;
    margin-bottom: 20px;
  }
}

.main-content__profile-new-photo {
  height: 400px;
  background: #344157;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
}

.main-content__profile-new-image {
  display: block;
  margin-bottom: 25px;
}

.add-photo__submit {
  color: rgba($white, 0.1);
  font-family: $robotoSlab;
  font-size: 20px;
  line-height: 1.25;
  display: block;
  text-decoration: none;
  flex: 1 1 100%;
  text-align: center;
}

.main-content__profile-item {
  td.main-content__profile-value--new {
    color: $white;
    font-family: $roboto;
    font-size: 16px;
    border: 0;

    @include mq(700px) {
      padding-left: 15px;
    }
  }
}

.profile__input {
  border: none;
  border-radius: 4px;
  color: rgba($white, 0.7);
  font-family: $roboto;
  font-size: 16px;
  background: #5c6574;
  padding-left: 30px;
  height: 45px;
  width: 100%;

  @include mq(700px) {
    padding: 5px 15px;
  }

  @include mq(400px) {
    flex: 0 1 calc(100% - 50px);
    font-size: 12px;
  }

  &:focus, &:active {
    outline: 0;
  }

  &::-webkit-input-placeholder {
    color: rgba($white, 0.7);
  }

  &::-moz-placeholder {
    color: rgba($white, 0.7);
  }

  &:-ms-input-placeholder {
    color: rgba($white, 0.7);
  }

  &:-moz-placeholder {
    color: rgba($white, 0.7);
  }
}
