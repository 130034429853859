//color naming by http://chir.ag/projects/name-that-color
$white: #fff;
$black: #000;

$cod-gray: #191818;
$black-haze: #e8eaea;
$mountain-meadow: #16b58d;
$gray: #808080;
$silver: #bfbfbf;
$scorpion: #5b5b5b;
$green-pea: #195245;
$gray-nurse: #dee2e0;
$athens-gray: #f2f1f4;
$dusty-gray: #999999;
$baltic-sea: #2a282f;
$prebaltic-sea: #2e2d35;
$mirage: #1e293a;
$pickled-bluewood: #2a374c;
$outrageous-orange: #ff5330;
$oslo-gray: #91959b;
$cardinal: #c2142e;
$alizarin-crimson: #e82929;